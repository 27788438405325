import React, { useState, useEffect } from 'react';
import axios from 'axios';
axios.defaults.withCredentials = true;


const API_BASE_URL_= 'https://jkkggcw.cool.newstack.be';
const API_BASE_URL = 'http://localhost:5000';


const DatacoproOAuthDemo = () => {
  const [token, setToken] = useState(null);
  const [profile, setProfile] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [supplierInfo, setSupplierInfo] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    // Check if there's a token in localStorage when the component mounts
    const storedToken = localStorage.getItem('datacopro_token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleLogin = () => {
    window.location.href = `${API_BASE_URL}/login`;
  };

  const handleProfile = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProfile(response.data);
    } catch (error) {
      console.error('Failed to fetch profile:', error);
    }
  };

  const handleRefreshToken = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/refresh_token`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setToken(response.data.new_token);
      localStorage.setItem('datacopro_token', response.data.new_token);
    } catch (error) {
      console.error('Failed to refresh token:', error);
    }
  };


  const handleGetContactInfo = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    try {
      //const response = await axios.get(`/get_contact_info?email=${email}`);
     // const response = await axios.get(`${API_BASE_URL}/test`);
      const response = await axios.get(`${API_BASE_URL}/get_contact_info?email=${email}`, {
        withCredentials: true,
      });
      console.log('Response Data:', response.data); // Log the response data for debugging

      // Check if the response contains the login page HTML
      if (typeof response.data === 'string' && response.data.includes('login')) {
        handleLogin();
      } else {
        console.log('contact Data:', response.data); // Log the response data for debugging

        setContactInfo(response.data);
      }
    } catch (error) {
      console.error('Error fetching contact info:', error); // Log the error for debugging

      setContactInfo({ error: 'Failed to fetch contact infso' });
    }
  };

  const handleGetSupplierInfo = async (e) => {
    e.preventDefault();
    const coproNumber = e.target.copro_number.value;
    const professionLabel = e.target.profession_label.value;
    try {
      const response = await axios.get(`/get_supplier_info?copro_number=${coproNumber}&profession_label=${professionLabel}`);
      setSupplierInfo(response.data.supplier_info);
    } catch (error) {
      setSupplierInfo({ error: 'Failed to fetch supplier info' });
    }
  };

  return (
    <div>
      <h1>-----!!----WelcomeTTTTT to Datacopro OAuth Demo</h1>
      <button onClick={handleLogin}>Login with Datacopro</button>
      <button onClick={handleProfile}>Get Profile Information</button>
      <button onClick={handleRefreshToken}>Refresh Token</button>

      {token && <h2>Token Generated: {token}</h2>}

      {profile && (
        <div>
          <h2>Profile Information</h2>
          <pre>{JSON.stringify(profile, null, 2)}</pre>
        </div>
      )}

      <h2>Contact Info</h2>
      <form onSubmit={handleGetContactInfo}>
        <label htmlFor="email">Email:</label>
        <input type="text" id="email" name="email" />
        <button type="submit">Get Contact Info</button>
      </form>
      {contactInfo && (
        contactInfo.error ? (
          <div className="error">{contactInfo.error}</div>
        ) : (
          <div>
            <h3>Contact Information:</h3>
            <pre>{JSON.stringify(contactInfo, null, 2)}</pre>
          </div>
        )
      )}

<div>
      <form onSubmit={handleGetContactInfo}>
        <input type="email" name="email" placeholder="Enter email" required />
        <button type="submit">Get Contact Info</button>
      </form>
      {contactInfo && (
        <div>
          <h2>Contact Info</h2>
          <pre>{JSON.stringify(contactInfo, null, 2)}</pre>
        </div>
      )}
      {error && <p>{error}</p>}
    </div>

      <h2>Supplier Info</h2>
      <form onSubmit={handleGetSupplierInfo}>
        <label htmlFor="copro_number">Copro Number:</label>
        <input type="text" id="copro_number" name="copro_number" />
        <label htmlFor="profession_label">Profession Label:</label>
        <input type="text" id="profession_label" name="profession_label" />
        <button type="submit">Get Supplier Info</button>
      </form>
      {supplierInfo && (
        supplierInfo.error ? (
          <div className="error">{supplierInfo.error}</div>
        ) : (
          <div>
            <h3>Supplier Information:</h3>
            <pre>{JSON.stringify(supplierInfo, null, 2)}</pre>
          </div>
        )
      )}
    </div>
  );
};

export default DatacoproOAuthDemo;